<template>
  <div class="history-list-card d-flex flex-column">
    <history-list-card-toolbar
      :title="title"
      :show-hide-lines-action="showHideLinesAction"
      :show-animate-track-action="showAnimateTrackAction"
      :hide-lines="hideLines"
      @click:action="handleAction"
      v-on="$listeners"
    />
    <base-virtual-list
      ref="list"
      :items="items"
      :loading="loading"
      :no-more-data="noMoreData"
      :bench="bench"
      :item-height="itemHeight"
      @scroll="handleScroll"
    />
    <div
      v-if="(!items || !items.length) && noDataMessage && !loading"
      class="text-center py-4"
    >
      {{ noDataMessage }}
    </div>
  </div>
</template>

<script>
import HistoryListCardToolbar from './HistoryListCardToolbar';
import { usePopup } from '@/compositions/popup';
import useMedia from '@/compositions/media';
import downloadBlob from '@/utils/downloadBlob';
import moment from 'moment';
import { useThrottleFn } from '@vueuse/core';
import fastdom from 'fastdom';
import { ref, onBeforeUnmount } from '@vue/composition-api';
import { useMap } from '@/compositions/map';

export default {
  name: 'HistoryListCard',
  components: {
    HistoryListCardToolbar
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showHideLinesAction: {
      type: Boolean,
      default: false
    },
    showAnimateTrackAction: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    itemHeight: {
      type: Number,
      default: 72
    },
    idProperties: {
      type: Array,
      default: () => []
    },
    idControls: {
      type: Array,
      default: () => []
    },
    objectId: {
      type: String,
      default: ''
    },
    bench: {
      type: [Number, String],
      default: 20
    },
    infinite: {
      type: Boolean,
      default: true
    },
    noDataMessage: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    noMoreData: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const list = ref(null);
    const { open } = usePopup();
    const hideLines = ref(false);
    const {
      getHistoryProperties,
      getObjectPropertiesHistory,
      getControlsHistory
    } = useMedia();

    const {
      track: { setAnimatingMode, isAnimatingMode }
    } = useMap();

    const handleScroll = useThrottleFn(e => {
      if (!props.infinite) return;
      fastdom.measure(() => {
        const { clientHeight, scrollTop, scrollHeight } = e.target;

        if (Math.round(clientHeight + scrollTop) === scrollHeight) {
          emit('infinite');
        }
      });
    }, 250);

    onBeforeUnmount(() => {
      if (isAnimatingMode.value) {
        setAnimatingMode(false);
      }
    });

    const handleAction = item => {
      const showDataShape =
        !props.idControls.length && !props.idProperties.length;
      switch (item) {
        case 'download':
          open({
            component: () => import('./HistoryDateRangePopup'),
            props: {
              showDataShape,
              onSubmit: async range => {
                if (props.idControls.length) {
                  const data = {
                    objects: props.idControls,
                    from: `${range.from} 00:00`,
                    to: `${range.to} 23:59`,
                    ...(showDataShape ? { shape: range.shape } : {})
                  };

                  getControlsHistory(data)
                    .then(res => {
                      downloadBlob(
                        res.data,
                        `PixelMonitor_ControlHistory_${range.from}-${range.to}.csv`,
                        res.data.type
                      );
                    })
                    .catch(() => {})
                    .finally(() => {});
                } else if (props.idProperties.length) {
                  const data = {
                    ids: props.idProperties,
                    from: `${range.from} 00:00`,
                    to: `${range.to} 23:59`
                  };

                  getHistoryProperties(data)
                    .then(res => {
                      downloadBlob(
                        res.data,
                        `PixelMonitor_PropertiesHistory_${range.from}-${range.to}.csv`,
                        res.data.type
                      );
                    })
                    .catch(() => {})
                    .finally(() => {});
                } else {
                  const data = {
                    ids: [props.objectId],
                    from: `${range.from} 00:00`,
                    to: `${range.to} 23:59`,
                    shape: range.shape,
                    include_fragments: true
                  };

                  getObjectPropertiesHistory(data)
                    .then(res => {
                      downloadBlob(
                        res.data,
                        `PixelMonitor_PropertiesHistory_${range.from}-${range.to}.csv`,
                        res.data.type
                      );
                    })
                    .catch(() => {})
                    .finally(() => {});
                }
              }
            }
          });
          break;
        case 'day': {
          const fromDate = moment()
            .subtract(1, 'days')
            .toISOString();
          emit('onRangeChanged', fromDate);
          break;
        }
        case 'week': {
          const fromDate = moment()
            .subtract(1, 'weeks')
            .toISOString();
          emit('onRangeChanged', fromDate);
          break;
        }
        case 'month': {
          const fromDate = moment()
            .subtract(1, 'months')
            .toISOString();
          emit('onRangeChanged', fromDate);
          break;
        }
        case 'customPeriod': {
          open({
            component: () => import('./CustomDateRangePopup.vue'),
            props: {
              onSubmit: ({ toDate, fromDate }) => {
                emit('onRangeChanged', fromDate, toDate);
              }
            }
          });
          break;
        }
        case 'hideLines': {
          hideLines.value = !hideLines.value;
          emit('onHideLinesChanged', hideLines.value);
          break;
        }
        case 'animateTrack': {
          setAnimatingMode(true);
          list.value.scrollToBottom();
        }
      }
    };
    return {
      handleAction,
      handleScroll,
      hideLines,
      list
    };
  }
};
</script>

<style lang="sass" scoped>
.history-list-card
  width: inherit
  height: inherit
</style>
